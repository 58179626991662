<template>
  <v-sheet
    rounded="lg"
    color="accent"
    min-height="calc(100vh - 200px)"
    class="pt-2"
  >
    <template> <b-breadcrumb :items="breadCrumbs"></b-breadcrumb></template>
    <v-row style="padding: 0 2%">
      <v-col cols="2">
        <BackActionBar @goback="goback"></BackActionBar>
      </v-col>
      <v-col cols="8">
        <ContextualActionBar title="Piano Operativo"></ContextualActionBar>
      </v-col>
    </v-row>
    <v-divider dark></v-divider>
    <TextReader @load="text = $event" :disableAlert="disableAlert" />
    <BadgesSectionLinks :infoBadges="infoBadges" />
    <ModalAlert
      @open-dialog-alert="updateDialogAlert"
      :dialogAlert="openDialogAlert"
      :alert="infoAlerts"
      :title="alertTitle"
    ></ModalAlert>
  </v-sheet>
</template>

<script>
import BadgesSectionLinks from "../components/core/BadgesSectionLinks.vue";
import ContextualActionBar from "../components/core/ContextualActionBar.vue";
import TextReader from "../components/core/TextReader.vue";
import { mapActions, mapGetters } from "vuex";
import connectionMixin from "@/mixins/connectionParams.js";
import ModalAlert from "@/components/ModalAlert.vue";
import BackActionBar from "../components/core/BackActionBar.vue";

export default {
  name: "PianoOperativo",
  mixins: [connectionMixin],
  components: {
    ContextualActionBar,
    BadgesSectionLinks,
    TextReader,
    ModalAlert,
    BackActionBar,
  },
  data() {
    return {
      infoBadges: [
        {
          title: "Scenario in corso",
          subtitle: "Apre l'attuale Piano attivo",
          link: "",
          icon: "mdi-notebook",
          action: "goToDetailPlan",
        },
        {
          title: "Caricamento analisi impatto",
          subtitle: "Carica la lista dei sistemi in fault",
          link: "",
          icon: "mdi-file-upload-outline",
          action: "openFileDialog",
          info: "mdi-information-outline",
        },
        {
          title: "Scenario predefinito",
          subtitle: "Seleziona uno degli scenari predefiniti",
          link: "/opplandefault",
          icon: "mdi-notebook-outline",
          action: "",
        },
      ],
      breadCrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Piano Operativo",
          href: "#",
        },
      ],
      disableAlert: false,
      text: "",
      idPlan: "",
      infoAlerts: [],
      openDialogAlert: false,
      alertTitle: "CARICAMENTO ANALISI IMPATTO",
      numNodesFound: 0,
      minTimeout: 3000,
    };
  },
  computed: {
    ...mapGetters("operationalModule", ["getActivation"]),
  },
  mounted() {
    this.getUserProfile();
  },
  methods: {
    ...mapActions("operationalModule", ["activate"]),
    async getUserProfile() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_USER"),
          this.opts
        );
        if (response.data == "readonly") {
          this.$router.push("/");
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
      }
    },
    goback() {
      this.$router.push("/");
    },
    updateDialogAlert(value) {
      this.openDialogAlert = value;
      if (value == false) {
        if (this.numNodesFound != 0) {
          this.$router.push({
            name: "OpPlanImpactId",
            params: {
              planId: this.idPlan,
            },
          });
        } else {
          location.reload();
        }
      }
    },
    async waitTimeout(timeout) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, timeout);
      });
    },
    async getDashboardImpatto(id, timeout = 0) {
      this.loading = true;
      var bodyData = {
        onlyProd: false,
      };
      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            this.$env.value("API_DASHBOARD_IMPATTO") +
            "/" +
            id,
          bodyData,
          this.opts
        );
        if (response) {
          if (!response.data.completed) {
            await this.waitTimeout(timeout);
            this.getDashboardImpatto(id, timeout);
          } else {
            if (response.data.report.message) {
              this.infoAlerts = [];
              this.infoAlerts.push(response.data.report.message);
              this.numNodesFound = response.data.report.numNodesFound;
              this.updateDialogAlert(true);
            }
            this.activate(false);
            localStorage.setItem("dataPlan", JSON.stringify(response.data));
            localStorage.setItem("planIsActive", false);
            this.loading = false;
            this.disableAlert = true;
          }
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        alert("Si prega di ricaricare la pagina");
      }
    },
  },
  watch: {
    text: function (val) {
      val.count = val.count <= this.minTimeout ? this.minTimeout : val.count;
      this.$http
        .post(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            this.$env.value("API_OSAVEPLAN"),
          val,
          this.opts
        )
        .then((response) => {
          this.idPlan = response.data;
          this.getDashboardImpatto(this.idPlan, val.count);
        })
        .catch((error) => {
          console.log(error);
          this.disableAlert = true;
          alert("Si prega di ricaricare la pagina");
        });
    },
    idPlan: function (val) {
      return val;
    },
  },
};
</script>
